import React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';
import PostFooter from '../components/PostFooter';

function BlogListCategoryTemplate({ data, pageContext }) {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Page pageContext={pageContext}>
      {posts.map(({ node }) => {
        return (
          <div key={node.fields.slug} style={{ marginBottom: '5rem' }}>
            <PageTitle
              title={node.frontmatter.title}
              subtitle={node.frontmatter.date}
              slug={`/blog${node.fields.slug}`}
            />
            <div dangerouslySetInnerHTML={{ __html: node.html }} />

            <PostFooter category={node.frontmatter.category} />
          </div>
        );
      })}
    </Page>
  );
}

export default BlogListCategoryTemplate;

export const pageQuery = graphql`
  query BlogListCategory($category: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "dddd, Do MMMM YYYY")
            title
            category
          }
        }
      }
    }
  }
`;
